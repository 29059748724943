/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
import { getError } from "../LoadingError/Utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import MapsView from "../Components/MapsView";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Post from "../Components/Post";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PostProfile from "../Components/PostProfile";
import { useNavigate } from "react-router-dom";
import MessageBox from "../LoadingError/MessageBox";
import LoadingBox from "../LoadingError/LoadingBox";
import Tooltip from "@mui/material/Tooltip";
import InfiniteScroll from "react-infinite-scroll-component";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, posts: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCategoryList: true };
    case "FETCHED":
      return {
        ...state,
        categoryList: action.payload,
        loadingCategoryList: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCategoryList: false,
        errorCategoryList: action.payload,
      };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingUserResult: true };
    case "FETCHED":
      return {
        ...state,
        userResult: action.payload,
        loadingUserResult: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingUserResult: false,
        errorUserResult: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingUserList: true };
    case "FETCHED":
      return { ...state, userList: action.payload, loadingUserList: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingUserList: false,
        errorUserList: action.payload,
      };
    default:
      return state;
  }
};

const SearchScreen = (props) => {
  const { switchFilter } = props;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [typePost, setTypePost] = useState(props.typePost);
  const searchInput  = props.statePropSearch;
  const searchFlag = props.statePropSearchFlag;
  const pu = props.statePropPu ? props.statePropPu : "post";

  const category = props.category;
  const [categorySd, setCategorySd] = useState([]);
  const location = props.location;
  const verified = props.verified;
  const date = props.date;

  const [showPostModal, setShowPostModal] = useState(false);
  const [postModalData, setPostModalData] = useState("");
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showMapsView, setShowMapsView] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [pageUser, setPageUser] = useState(1);
  const [hasMoreUser, setHasMoreUser] = useState(true);

  const navigate = useNavigate();

  const [{ loading, error, posts }, dispatch] = useReducer(reducer, {
    posts: [],
    loading: false,
    error: "",
  });

  const [allPost, setAllPost] = useState([]);

  const [allUser, setAllUser] = useState([]);

  const [{ loadingCategoryList, errorCategoryList, categoryList }, dispatch2] =
    useReducer(reducer2, {
      categoryList: [],
      loadingCategoryList: true,
      errorCategoryList: "",
    });

  const [
    { loadingUserList, errorUserList, pageUserList, userList },
    dispatch4,
  ] = useReducer(reducer4, {
    userList: [],
    loadingUserList: false,
    errorUserList: "",
  });

  // first render post
  useEffect(() => {

    if (
      searchFlag === false ||
      pu !== "post" ||
      (allPost.length !== 0 && !searchFlag) ||
      loading
    ) {
      return;
    }


    const fetchDataPost = async () => {

      dispatch({
        type: "FETCHING",
      });

      let tempPage = 1;

      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BACKEND_DOMAIN
          }/api/posts/search?query=${searchInput}&typePost=${JSON.stringify(
            typePost
          )}&category=${category}&location=${location}&row=10&page=${tempPage}&verified=${verified}&dayDiff=${date}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        if (response.data.data.length < 10) {
          setHasMore(false);
        }

        dispatch({
          type: "FETCHED",
          payload: response.data.data,
        });

        
        if (searchFlag) {
          setAllPost(response.data.data);
        } else {
          setAllPost((prevPost) => [...prevPost, ...response.data.data]);
        }

        props.onChildStateChangeSearchFlag(false);
      } catch (err) {
        toast.error(getError(err));
        dispatch({
          type: "FETCH_FAILED",
          payload: err.message,
        });
      }
    };

    fetchDataPost();
  }, [
    searchInput,
    searchFlag,
    category,
    location,
    verified,
    pageUser,
    date,
    typePost,
    page,
    pu,
  ]);


  // first render user

  useEffect(() => {

    let tempPage = 1;

    if (  
      searchFlag === false ||
      pu !== "user" ||
      (allUser.length !== 0 && !searchFlag) ||
      loadingUserList
    ) {
      return;
    }

    const fetchDataUser = async () => {
      dispatch4({
        type: "FETCHING",
      });

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/searchUser?query=${searchInput}&row=10&page=${tempPage}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch4({
          type: "FETCHED",
          payload: response.data.data,
        });
 

        if (searchFlag) {
          setAllUser(response.data.data);
        } else {
          setAllUser((prevUser) => [...prevUser, ...response.data.data]);
        }

        props.onChildStateChangeSearchFlag(false);
      } catch (err) {
        toast.error(getError(err));
        dispatch4({
          type: "FETCH_FAILED",
          payload: err.message,
        });
      }
    };

    fetchDataUser();
  }, [pu, searchInput, searchFlag]);

  const loadMorePosts = async () => {
    if ((hasMore && pu === "post") || (hasMoreUser && pu === "user")) {
      let tempPage = pu === "post" ? page + 1 : pageUser + 1;
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_BACKEND_DOMAIN
            }/api/posts/search?query=${searchInput}&typePost=${JSON.stringify(
              typePost
            )}&category=${category}&location=${location}&row=10&page=${tempPage}&verified=${verified}&dayDiff=${date}`,
            {
              auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
              },
            }
          );

          if (response.data.data.length < 10) {
            setHasMore(false);
          }

          setAllPost((prevUser) => [...prevUser, ...response.data.data]);
        } catch (err) {}
      };

      const fetchDataUser = async () => {

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/searchUser?query=${searchInput}&row=10&page=${tempPage}`,
            {
              auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
              },
            }
          );
          if (response.data.data.length < 10) {
            setHasMoreUser(false);
          }

          setAllUser((prevUser) => [...prevUser, ...response.data.data]);
        } catch (err) {}
      };

      // Perbarui halaman
      if (pu === "post") {
        fetchData();
        setPage((prevPage) => prevPage + 1);
      } else {
        fetchDataUser();
        setPageUser((prevPage) => prevPage + 1);
      }
    }
  };

  // Memantau perubahan pada PU
  useEffect(() => {
    // Memanggil permintaan pencarian ketika PU berubah
    props.onChildStateChangeSearchFlag(true);

  }, [pu]);

  // Memantau perubahan pada typePost
  useEffect(() => {
    // Memanggil permintaan pencarian ketika typePost berubah
    props.onChildStateChangeSearchFlag(true);
  }, [typePost]);

  useEffect(() => {
    setTypePost(props.typePost);
  }, [props.typePost]);

  const postTypeList = [
    { type: 1, subcategories: [1, 2], typeName: "Product" },
    { type: 2, subcategories: [1, 2], typeName: "Service" },
    { type: 3, subcategories: [1, 2], typeName: "Event" },
    { type: 4, subcategories: [1, 2], typeName: "Job" },
    { type: 5, subcategories: [1, 2], typeName: "Insight" },
  ];

  const setSelectedTypePost = (pType) => {
    const result = [];

    const selectedSubcategoriesLength = pType && pType.subcategories.length;

    if (selectedSubcategoriesLength > 0) {
      for (let i = 0; i < selectedSubcategoriesLength; i++) {
        const newObj = {
          post_type: pType.type,
          sd_post_type: pType.subcategories[i],
        };
        result.push(newObj);
      }
    }

    setTypePost(result);
  };

  // Memantau perubahan pada location
  useEffect(() => {
    // Memanggil permintaan pencarian ketika location berubah
    props.onChildStateChangeSearchFlag(true);
  }, [location]);

  // Memantau perubahan pada verified
  useEffect(() => {
    // Memanggil permintaan pencarian ketika verified berubah
    props.onChildStateChangeSearchFlag(true);
  }, [verified]);

  // Memantau perubahan pada date
  useEffect(() => {
    // Memanggil permintaan pencarian ketika date berubah
    props.onChildStateChangeSearchFlag(true);
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch2({
        type: "FETCHING",
      });

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCategoryOptions`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: response.data.data,
        });
      } catch (err) {
        toast.error(getError(err));
        dispatch2({
          type: "FETCH_FAILED",
          payload: err.message,
        });
      }
    };

    fetchData();
  }, []);

  const handleOpenPostModal = (post) => {
    setShowPostModal(true);

    setPostModalData(post);
  };

  function handleClosePostModal() {
    setShowPostModal(false);

    setPostModalData("");
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const wordLimit = 20; // Set your word limit here

  function SetDefaultImage({ post }) {
    const mediaUrl = post.images.length > 0 ? post.images[0] : "";
  
    const isVideo = mediaUrl.includes("smpost-v-");

    return isVideo ? (
      // Render the video element as a static thumbnail
      <video
        className="w-[300px] h-[300px] object-cover rounded-2xl"
        src={mediaUrl}
        muted
        playsInline
        preload="metadata" // Load only metadata and first frame
        disablePictureInPicture
        style={{ pointerEvents: "none" }} // Disable user interaction
      />
    ) : mediaUrl? (
      // Render an image for non-video media
      <img
        src={mediaUrl}
        alt="Media thumbnail"
        loading="lazy"
        style={{
          width: "300px",
          height: "300px",
          objectFit: "cover",
          borderRadius: "0.5rem",
        }}
      />
    ):(
      <div
      style={{
        width: "300px",
        height: "300px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <p
        className="m-12 text-lg font-semibold"
        style={{ fontStyle: "italic", quotes: '"“" "”"' }}
      >
        “{truncateText(post.description, wordLimit)}”
      </p>
    </div>
    );
  }
  

  const handleToggleMaps = () => {
    setShowMapsView(!showMapsView);
  };

  const typeNames = {
    1: "Product",
    2: "Service",
    3: "Event",
    4: "Job",
    5: "Insight",
    6: "Education",
  };

  const getTypeName = (postType, sdType) => {
    // if (postType === 5 && sdType.value === 1) {
    //   return "Review"; // Adjust as needed if sdType.value should dynamically change the type
    // } else if (postType === 5 && sdType.value === 2) {
    //   return "Question"; // Adjust as needed for sdType.value === 2
    // } else {
    return typeNames[postType] || "Unknown Type";
    // }
  };

  const typeColors = {
    Product: "bg-green-600",
    Service: "bg-blue-600",
    Event: "bg-orange-600",
    Job: "bg-purple-600",

    Insight: "bg-blue-500",
  };

  const defaultColor = "bg-gray-600"; // Default color for unknown types

  const getTypeColor = (postType, sdType) => {
    if (postType === 5) {
      if (sdType.value === 1) {
        return typeColors["Review"] || defaultColor;
      } else if (sdType.value === 2) {
        return typeColors["Question"] || defaultColor;
      }
    }

    // Default to typeColors based on postType if not Review or Question
    return typeColors[typeNames[postType]] || defaultColor;
  };

  useEffect(() => {}, [showMapsView]);

  function handleCloseSignInModal() {
    setShowSignInModal(false);
  }

  function handleSignInButtonModal() {
    setShowSignInModal(false);
    navigate("/signin");
  }

  function handleCancelButtonModal() {
    setShowSignInModal(false);
  }

  return (
    <div className="h-full mb-8">
      <Helmet>
        <title>Search | SiapHub</title>
      </Helmet>
      {/* {loading && loadingUserList ? (
        <div className="flex justify-center pt-6 ">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : ( */}
      <div className="h-full overflow-hidden">
        {pu === "user" ? (
          <>
            <div
              className={`flex-col ${
                showMapsView ? "overflow-hidden h-full" : ""
              }`}
            >
              {/* Grid Area */}
              <div className="flex h-[8%] sticky z-10 top-[0px]">
                <div className="flex justify-end w-full top-3 bg-[#F5F5F5] top-[0px]">
                  <div className="flex items-center hover:bg-[#F5F5F5] rounded-lg bg-white drop-shadow-lg mx-5 mt-4 mb-1 h-12 p-2">
                    <>
                      {showMapsView ? (
                        <>
                          <Tooltip title="Grid View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={
                                userInfo
                                  ? handleToggleMaps
                                  : () => setShowSignInModal(true)
                              }
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18V22C14 22.5304 13.7893 23.0391 13.4142 23.4142C13.0391 23.7893 12.5304 24 12 24H8C7.46957 24 6.96086 23.7893 6.58579 23.4142C6.21071 23.0391 6 22.5304 6 22V18C6 17.4696 6.21071 16.9609 6.58579 16.5858C6.96086 16.2107 7.46957 16 8 16H12ZM22 16C22.5046 15.9998 22.9906 16.1904 23.3605 16.5335C23.7305 16.8766 23.9572 17.3468 23.995 17.85L24 18V22C24.0002 22.5046 23.8096 22.9906 23.4665 23.3605C23.1234 23.7305 22.6532 23.9572 22.15 23.995L22 24H18C17.4954 24.0002 17.0094 23.8096 16.6395 23.4665C16.2695 23.1234 16.0428 22.6532 16.005 22.15L16 22V18C15.9998 17.4954 16.1904 17.0094 16.5335 16.6395C16.8766 16.2695 17.3468 16.0428 17.85 16.005L18 16H22ZM12 18H8V22H12V18ZM22 18H18V22H22V18ZM22 6C22.5046 5.99984 22.9906 6.19041 23.3605 6.5335C23.7305 6.87659 23.9572 7.34684 23.995 7.85L24 8V12C24.0002 12.5046 23.8096 12.9906 23.4665 13.3605C23.1234 13.7305 22.6532 13.9572 22.15 13.995L22 14H18C17.4954 14.0002 17.0094 13.8096 16.6395 13.4665C16.2695 13.1234 16.0428 12.6532 16.005 12.15L16 12V8C15.9998 7.49542 16.1904 7.00943 16.5335 6.63945C16.8766 6.26947 17.3468 6.04284 17.85 6.005L18 6H22ZM12 6C12.5046 5.99984 12.9906 6.19041 13.3605 6.5335C13.7305 6.87659 13.9572 7.34684 13.995 7.85L14 8V12C14.0002 12.5046 13.8096 12.9906 13.4665 13.3605C13.1234 13.7305 12.6532 13.9572 12.15 13.995L12 14H8C7.49542 14.0002 7.00943 13.8096 6.63945 13.4665C6.26947 13.1234 6.04284 12.6532 6.005 12.15L6 12V8C5.99984 7.49542 6.19041 7.00943 6.5335 6.63945C6.87659 6.26947 7.34684 6.04284 7.85 6.005L8 6H12ZM22 8H18V12H22V8ZM12 8H8V12H12V8Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Maps View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={
                                userInfo
                                  ? handleToggleMaps
                                  : () => setShowSignInModal(true)
                              }
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>

              {showMapsView ? (
                <div className="flex h-[92%] justify-center mx-5">
                  <MapsView posts={allUser} />
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={allUser.length}
                  next={loadMorePosts}
                  scrollThreshold={0.9}
                  hasMore={hasMore}
                  height={"80vh"}
                  loader={
                    <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                      <LoadingBox />
                    </div>
                  }
                >
                  <>
                    <div className="flex justify-center w-full">
                      <div className="flex flex-col w-full ml-8 mt-6 mb-8 mr-8">
                        {allUser.map((user) => (
                          <Link
                            key={user.id}
                            to={`/${user.username}`}
                            className="flex rounded-md bg-white drop-shadow-lg w-full p-8 mb-6"
                          >
                            <div className="w-full bg-white p-4 rounded-md shadow">
                              <ImageListItemBar
                                title={
                                  <div className="flex items-center text-black">
                                    {user.picture ? (
                                      <img
                                        className="rounded-full w-12 h-12"
                                        src={user.picture}
                                        alt="profilepicture"
                                      />
                                    ) : (
                                      <img
                                        className="rounded-full w-12 h-12"
                                        src="../images/pp/defaultpp.png"
                                        alt="defaultprofilepicture"
                                        referrerPolicy="no-referrer"
                                      />
                                    )}

                                    <div className="flex h-20">
                                      <div className="flex flex-col ml-4 text-black">
                                        <div className="flex">
                                          <span className="text-lg font-bold cursor-pointer">
                                            {user.name}
                                          </span>
                                          <div className="flex items-center ml-2">
                                            {user.verified ? (
                                              <img
                                                className="w-4 h-4"
                                                alt="verified"
                                                src="../../images/icon_done.png"
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        <span className="text-lg cursor-pointer">
                                          @{user.username}
                                        </span>
                                        <span className="text-lg cursor-pointer">
                                          {user.business_category
                                            ? user.business_category
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                }
                                style={{
                                  borderRadius: "0.5em",
                                  background: "white",
                                  color: "black",
                                }}
                              />
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </>
                </InfiniteScroll>
              )}
            </div>
          </>
        ) : (
          <div
            className={`flex-col ${
              showMapsView ? "overflow-hidden h-full" : ""
            }`}
          >
            <div className="flex h-[8%] sticky top-[0px] z-10">
              <div className="flex justify-center w-full top-3 bg-[#F5F5F5]">
                <div className="flex rounded-md bg-white drop-shadow-lg w-full h-12 mx-5 mt-4 mb-1 p-2 overflow-x-auto justify-between">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflowX: "auto",
                      display: "flex",
                      alignItems: "center",
                      overflowY: "hidden",
                    }}
                  >
                    <a
                      key="all"
                      className={`rounded-md ${
                        typePost === null || typePost.length === 0
                          ? "bg-[#4f4f4f] hover:bg-stone-700"
                          : "bg-[#F95C3D] hover:bg-orange-600"
                      } text-white p-1 cursor-pointer`}
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        setTypePost(null);
                      }}
                    >
                      All Post Type
                    </a>
                    {!userInfo ? (
                      <>
                        {postTypeList.map((pType) => (
                          <a
                            key={pType.type}
                            className={`rounded-md text-white p-1 cursor-pointer ${
                              typePost &&
                              (typePost.length === 2 ||
                                typePost.length === 1) &&
                              typePost[0].post_type === pType.type
                                ? "bg-[#4f4f4f] hover:bg-stone-700"
                                : "bg-[#F95C3D] hover:bg-orange-600"
                            }`}
                            style={{ marginRight: "8px" }}
                            onClick={() => setShowSignInModal(true)}
                          >
                            {pType.typeName}
                          </a>
                        ))}
                      </>
                    ) : (
                      <>
                        {postTypeList.map((pType) => (
                          <a
                            key={pType.type}
                            className={`rounded-md text-white p-1 cursor-pointer ${
                              typePost &&
                              (typePost.length === 2 ||
                                typePost.length === 1) &&
                              typePost[0].post_type === pType.type
                                ? "bg-[#4f4f4f] hover:bg-stone-700"
                                : "bg-[#F95C3D] hover:bg-orange-600"
                            }`}
                            style={{ marginRight: "8px" }}
                            onClick={() => setSelectedTypePost(pType)}
                          >
                            {pType.typeName}
                          </a>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="flex items-center hover:bg-[#F5F5F5] rounded-lg">
                    {!userInfo ? (
                      <>
                        {showMapsView ? (
                          <Tooltip title="Grid View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={() => setShowSignInModal(true)}
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18V22C14 22.5304 13.7893 23.0391 13.4142 23.4142C13.0391 23.7893 12.5304 24 12 24H8C7.46957 24 6.96086 23.7893 6.58579 23.4142C6.21071 23.0391 6 22.5304 6 22V18C6 17.4696 6.21071 16.9609 6.58579 16.5858C6.96086 16.2107 7.46957 16 8 16H12ZM22 16C22.5046 15.9998 22.9906 16.1904 23.3605 16.5335C23.7305 16.8766 23.9572 17.3468 23.995 17.85L24 18V22C24.0002 22.5046 23.8096 22.9906 23.4665 23.3605C23.1234 23.7305 22.6532 23.9572 22.15 23.995L22 24H18C17.4954 24.0002 17.0094 23.8096 16.6395 23.4665C16.2695 23.1234 16.0428 22.6532 16.005 22.15L16 22V18C15.9998 17.4954 16.1904 17.0094 16.5335 16.6395C16.8766 16.2695 17.3468 16.0428 17.85 16.005L18 16H22ZM12 18H8V22H12V18ZM22 18H18V22H22V18ZM22 6C22.5046 5.99984 22.9906 6.19041 23.3605 6.5335C23.7305 6.87659 23.9572 7.34684 23.995 7.85L24 8V12C24.0002 12.5046 23.8096 12.9906 23.4665 13.3605C23.1234 13.7305 22.6532 13.9572 22.15 13.995L22 14H18C17.4954 14.0002 17.0094 13.8096 16.6395 13.4665C16.2695 13.1234 16.0428 12.6532 16.005 12.15L16 12V8C15.9998 7.49542 16.1904 7.00943 16.5335 6.63945C16.8766 6.26947 17.3468 6.04284 17.85 6.005L18 6H22ZM12 6C12.5046 5.99984 12.9906 6.19041 13.3605 6.5335C13.7305 6.87659 13.9572 7.34684 13.995 7.85L14 8V12C14.0002 12.5046 13.8096 12.9906 13.4665 13.3605C13.1234 13.7305 12.6532 13.9572 12.15 13.995L12 14H8C7.49542 14.0002 7.00943 13.8096 6.63945 13.4665C6.26947 13.1234 6.04284 12.6532 6.005 12.15L6 12V8C5.99984 7.49542 6.19041 7.00943 6.5335 6.63945C6.87659 6.26947 7.34684 6.04284 7.85 6.005L8 6H12ZM22 8H18V12H22V8ZM12 8H8V12H12V8Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Maps View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={() => setShowSignInModal(true)}
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <>
                        {showMapsView ? (
                          <Tooltip title="Grid View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={handleToggleMaps}
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18V22C14 22.5304 13.7893 23.0391 13.4142 23.4142C13.0391 23.7893 12.5304 24 12 24H8C7.46957 24 6.96086 23.7893 6.58579 23.4142C6.21071 23.0391 6 22.5304 6 22V18C6 17.4696 6.21071 16.9609 6.58579 16.5858C6.96086 16.2107 7.46957 16 8 16H12ZM22 16C22.5046 15.9998 22.9906 16.1904 23.3605 16.5335C23.7305 16.8766 23.9572 17.3468 23.995 17.85L24 18V22C24.0002 22.5046 23.8096 22.9906 23.4665 23.3605C23.1234 23.7305 22.6532 23.9572 22.15 23.995L22 24H18C17.4954 24.0002 17.0094 23.8096 16.6395 23.4665C16.2695 23.1234 16.0428 22.6532 16.005 22.15L16 22V18C15.9998 17.4954 16.1904 17.0094 16.5335 16.6395C16.8766 16.2695 17.3468 16.0428 17.85 16.005L18 16H22ZM12 18H8V22H12V18ZM22 18H18V22H22V18ZM22 6C22.5046 5.99984 22.9906 6.19041 23.3605 6.5335C23.7305 6.87659 23.9572 7.34684 23.995 7.85L24 8V12C24.0002 12.5046 23.8096 12.9906 23.4665 13.3605C23.1234 13.7305 22.6532 13.9572 22.15 13.995L22 14H18C17.4954 14.0002 17.0094 13.8096 16.6395 13.4665C16.2695 13.1234 16.0428 12.6532 16.005 12.15L16 12V8C15.9998 7.49542 16.1904 7.00943 16.5335 6.63945C16.8766 6.26947 17.3468 6.04284 17.85 6.005L18 6H22ZM12 6C12.5046 5.99984 12.9906 6.19041 13.3605 6.5335C13.7305 6.87659 13.9572 7.34684 13.995 7.85L14 8V12C14.0002 12.5046 13.8096 12.9906 13.4665 13.3605C13.1234 13.7305 12.6532 13.9572 12.15 13.995L12 14H8C7.49542 14.0002 7.00943 13.8096 6.63945 13.4665C6.26947 13.1234 6.04284 12.6532 6.005 12.15L6 12V8C5.99984 7.49542 6.19041 7.00943 6.5335 6.63945C6.87659 6.26947 7.34684 6.04284 7.85 6.005L8 6H12ZM22 8H18V12H22V8ZM12 8H8V12H12V8Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Maps View">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`scale-125 hover:cursor-pointer`}
                              onClick={handleToggleMaps}
                            >
                              <circle cx="15" cy="15" r="15" fill="none" />
                              <path
                                d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                                fill="black"
                              />
                            </svg>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showMapsView ? (
              <div className="flex h-[92%] justify-center mx-5">
                {loading ? (
                  <div className="flex justify-center pt-6 ">
                    <LoadingBox />
                  </div>
                ) : error ? (
                  <MessageBox severity="error">{error}</MessageBox>
                ) : (
                  <MapsView posts={allPost} />
                )}
              </div>
            ) : (
              <>
                <InfiniteScroll
                  dataLength={allPost.length}
                  next={loadMorePosts}
                  scrollThreshold={0.9}
                  hasMore={hasMore}
                  // height={
                  //   window.innerHeight > 870
                  //     ? window.innerHeight - 80
                  //     : window.innerHeight - 100
                  // }
                  height={"80vh"}
                  loader={
                    <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                      <LoadingBox />
                    </div>
                  }
                >
                  <>
                    {/* Desktop Version */}
                    <div className="flex ml-5 mt-3 mb-5 mr-5 p-2 overflow-x-auto hidden lg:flex">
                      <ImageList
                        sx={{ width: "100%", height: "auto" }}
                        cols={3}
                      >
                        {allPost.map((post) => (
                          <ImageListItem
                            key={post.slug}
                            className="cursor-pointer relative overflow-hidden bg-white"
                            style={{
                              alignItems: "center",
                              border: "0.1px solid gainsboro",
                              borderRadius: "0.5em",
                            }}
                            onClick={() => handleOpenPostModal(post)}
                          >
                            <div className="absolute right-0 top-0 h-16 w-16">
                              <div
                                className={`absolute transform rotate-45 text-center font-semibold py-1 right-[-35px] top-[32px] w-[170px] ${getTypeColor(
                                  post.post_type,
                                  post.sd_post_type
                                )} text-white`}
                              >
                                {getTypeName(post.post_type, post.sd_post_type)}
                              </div>
                            </div>
                            <SetDefaultImage post={post} />
                            <ImageListItemBar
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {post.picture ? (
                                    <img
                                      className="rounded-full w-12 h-12"
                                      src={post.picture}
                                      alt="profilepicture"
                                    />
                                  ) : (
                                    <img
                                      className="rounded-full w-12 h-12"
                                      src="../images/pp/defaultpp.png"
                                      alt="defaultprofilepicture"
                                      referrerPolicy="no-referrer"
                                    />
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "1rem",
                                    }}
                                  >
                                    <span className="text-white text-lg font-bold cursor-pointer">
                                      {post.username
                                        ? post.username
                                        : post.name}
                                    </span>
                                    <span className="text-white text-lg font-bold cursor-pointer">
                                      {post.posttitle}
                                    </span>
                                  </div>
                                </div>
                              }
                              actionIcon={
                                <div style={{ marginLeft: "auto" }}>
                                  <span className="text-white text-lg font-bold cursor-pointer mr-3 hidden md:inline-block">
                                    {post.price ? (
                                      <>
                                        {" "}
                                        {new Intl.NumberFormat("id-ID", {
                                          style: "currency",
                                          currency: post.abbreviation,
                                          maximumFractionDigits: 0,
                                        }).format(post.price)}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              }
                              style={{
                                borderBottomLeftRadius: "0.5em",
                                borderBottomRightRadius: "0.5em",
                              }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                      <Modal
                        open={showPostModal}
                        onClose={handleClosePostModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                          zIndex: 1500,
                        }}
                        className="flex items-center justify-center"
                      >
                        <div className="bg-white rounded-lg p-4 w-9/12 max-h-[90%] outline-none overflow-y-auto">
                          <PostProfile
                            onePost={postModalData}
                            switchFilter={switchFilter}
                          />
                        </div>
                      </Modal>
                    </div>

                    {/* Mobile Version */}
                    <div className="flex ml-5 mt-6 mb-5 mr-5 p-2 overflow-x-auto lg:hidden">
                      <ImageList
                        sx={{ width: "100%", height: "auto" }}
                        cols={1}
                      >
                        {allPost.map((post) => (
                          <ImageListItem
                            key={post.slug}
                            className="cursor-pointer relative overflow-hidden bg-white"
                            style={{
                              alignItems: "center",
                              border: "0.1px solid gainsboro",
                              borderRadius: "0.5em",
                            }}
                            onClick={() => handleOpenPostModal(post)}
                          >
                            <div className="absolute right-0 top-0 h-16 w-16">
                              <div
                                className={`absolute transform rotate-45 text-center font-semibold py-1 right-[-35px] top-[32px] w-[170px] ${getTypeColor(
                                  post.post_type,
                                  post.sd_post_type
                                )} text-white`}
                              >
                                {getTypeName(post.post_type, post.sd_post_type)}
                              </div>
                            </div>
                            <SetDefaultImage post={post} />
                            <ImageListItemBar
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {post.picture ? (
                                    <img
                                      className="rounded-full w-12 h-12"
                                      src={post.picture}
                                      alt="profilepicture"
                                    />
                                  ) : (
                                    <img
                                      className="rounded-full w-12 h-12"
                                      src="../images/pp/defaultpp.png"
                                      alt="defaultprofilepicture"
                                      referrerPolicy="no-referrer"
                                    />
                                  )}

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "1rem",
                                    }}
                                  >
                                    <span className="text-white text-m font-bold cursor-pointer">
                                      {post.username
                                        ? post.username
                                        : post.name}
                                    </span>
                                    <span className="text-white text-m font-bold cursor-pointer">
                                      {post.posttitle}
                                    </span>
                                  </div>
                                </div>
                              }
                              actionIcon={
                                <div style={{ marginLeft: "auto" }}>
                                  <span className="text-white text-m font-bold cursor-pointer mr-3">
                                    {post.price ? (
                                      <>
                                        {" "}
                                        {new Intl.NumberFormat("id-ID", {
                                          style: "currency",
                                          currency: post.abbreviation,
                                          maximumFractionDigits: 0,
                                        }).format(post.price)}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              }
                              style={{
                                borderBottomLeftRadius: "0.5em",
                                borderBottomRightRadius: "0.5em",
                              }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                      <Modal
                        open={showPostModal}
                        onClose={handleClosePostModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="flex items-center justify-center"
                      >
                        <div className="bg-white rounded-lg p-4 w-9/12 max-h-[90%] outline-none overflow-y-auto">
                          <PostProfile
                            post={postModalData}
                            switchFilter={switchFilter}
                          />
                        </div>
                      </Modal>
                    </div>
                  </>
                </InfiniteScroll>
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        open={showSignInModal}
        onClose={handleCloseSignInModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            Sign in to use this feature
          </Typography>
          <div className="flex justify-center">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleSignInButtonModal}
            >
              Sign in
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleCancelButtonModal}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchScreen;
